import ProgrammeLayout from '@/theme/layouts/ProgrammeLayout.vue'

const List = () =>
  import(/* webpackChunkName: "dashboard" */ 'src/pages/Dashboard/Speakers/List.vue')

const Details = () =>
  import(/* webpackChunkName: "dashboard" */ 'src/pages/Dashboard/Speakers/Details.vue')

export default [
  {
    path: '/speakers-list',
    component: ProgrammeLayout,
    name: 'Speakers List',
    children: [
      {
        path: '/speakers-list',
        component: List
      },
      {
        path: '/speakers/:id',
        component: Details
      }
    ]
  }
]
