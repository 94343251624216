import VueRouter from 'vue-router'
import routes from './routes'
import secureStorage from '@/utils/secureStorage'

const router = new VueRouter({
  mode: 'history',
  routes,
  linkActiveClass: 'active',
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0, left: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  const user = secureStorage.getItem('user')
  const userJson = JSON.parse(user)

  if (to.matched.some(record => record.meta.requiresAuth) && !user) {
    return router.push({ name: 'Login', query: { redirect: to.fullPath } })
  } else if (user && userJson.user.is_sponsor && to.matched.some(record => record.meta.redirectSponsor)) {
    return router.push({ name: 'SponsorBlock' })
  }
  next()
})

export default router
