<template>
  <router-view />
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'App',
  metaInfo: {
    title: 'EuGMS 2024'
  },
  data () {
    return {
      deviceId: null
    }
  },
  watch: {
    $route (to, from) {
      this.windowParent.postMessage({ type: 'routeChange', data: to.path }, '*')
    },
    deviceId: {
      handler (val) {
        if (val) {
          this.loginByDevice({ pushDeviceId: val })
        }
      }
    }
  },
  mounted () {
    this.thisWindow.addEventListener('deviceIdStored', (event) => {
      this.deviceId = event.detail.deviceId
    })
  },
  methods: {
    ...mapActions({
      loginByDevice: 'auth/loginByDevice'
    })
  }
}
</script>
