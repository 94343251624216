import ProgrammeLayout from '../../theme/layouts/ProgrammeLayout.vue'

const List = () =>
  import(/* webpackChunkName: "dashboard" */ 'src/pages/Dashboard/Messages/List.vue')

const Details = () =>
  import(/* webpackChunkName: "dashboard" */ 'src/pages/Dashboard/Messages/Details.vue')

const ListUsers = () =>
  import(/* webpackChunkName: "dashboard" */ 'src/pages/Dashboard/Messages/ListUsers.vue')

export default [
  {
    path: '/messages',
    component: ProgrammeLayout,
    name: 'Message List',
    meta: { requiresAuth: true, redirectSponsor: true },
    children: [
      {
        path: '/messages',
        component: List
      },
      {
        path: '/messages/users',
        component: ListUsers
      },
      {
        path: '/messages/:userId(\\d+)',
        component: Details,
        name: 'chatMessageDetails'
      }
    ]
  }
]
