import axios from 'axios'
import Crud from '@/store/utils/crud'
const crud = new Crud('/notifications')

const state = () => ({
  ...crud.state
})
const getters = {
  ...crud.getters
}
const actions = {
  ...crud.actions,
  async setNotificationRead (context, id) {
    return await new Promise((resolve, reject) => {
      axios({
        method: 'PUT',
        url: '/notifications/' + id + '/read',
        data: null,
        headers: { 'Content-Type': 'application/json' }
      }).then((response) => {
        resolve(response)
      }, (error) => {
        reject(error)
      })
    })
  },
  async setAllNotificationsRead (context, id) {
    return await new Promise((resolve, reject) => {
      axios({
        method: 'PUT',
        url: '/notifications/set-all-notifications-read',
        data: null,
        headers: { 'Content-Type': 'application/json' }
      }).then((response) => {
        resolve(response)
      }, (error) => {
        reject(error)
      })
    })
  },
  async setNotificationListed (context, id) {
    return await new Promise((resolve, reject) => {
      axios({
        method: 'PUT',
        url: '/notifications/' + id + '/listed',
        data: null,
        headers: { 'Content-Type': 'application/json' }
      }).then((response) => {
        resolve(response)
      }, (error) => {
        reject(error)
      })
    })
  },
  async setAllNotificationsListed (context, id) {
    return await new Promise((resolve, reject) => {
      axios({
        method: 'PUT',
        url: '/notifications/set-all-notifications-listed',
        data: null,
        headers: { 'Content-Type': 'application/json' }
      }).then((response) => {
        resolve(response)
      }, (error) => {
        reject(error)
      })
    })
  }
}
const mutations = {
  ...crud.mutations
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
