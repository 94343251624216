import axios from 'axios'
import Crud from '@/store/utils/crud'
const crud = new Crud('/sessions')

const state = () => ({
  ...crud.state,
  dateSummary: null
})
const getters = {
  ...crud.getters,
  dateSummary: state => state.dateSummary ? state.dateSummary.content : []
}
const actions = {
  ...crud.actions,
  async fetchDateSummary (context, { id, payload }) {
    return await new Promise((resolve, reject) => {
      axios({
        method: 'GET',
        url: '/sessions-date-summary',
        data: payload,
        headers: { 'Content-Type': 'application/json' }
      }).then((response) => {
        context.commit('SET_DATE_SUMMARY_PAYLOAD', response)
        resolve(response)
      }, (error) => {
        reject(error)
      })
    })
  },

  resetDataSummary (context) {
    context.commit('CLEAR_DATA_SUMMARY_PAYLOAD')
  }
}
const mutations = {
  ...crud.mutations,
  SET_DATE_SUMMARY_PAYLOAD (state, response) {
    state.dateSummary = response.data
  },
  CLEAR_DATA_SUMMARY_PAYLOAD (state) {
    state.dateSummary = null
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
