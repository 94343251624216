<template>
  <div v-if="!isLoading" class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar, 'no-scroll': lockScreen }">
    <LockScreen v-if="lockScreen" />
    <notifications />
    <!--Share plugin (for demo purposes). You can remove it if don't plan on using it-->
    <div class="main-panel" :data="sidebarBackground">
      <zoom-center-transition :duration="100" mode="out-in">
        <!-- your content here -->
        <router-view />
      </zoom-center-transition>
    </div>
    <programme-footer />
  </div>
</template>
<script>
/* eslint-disable no-new */
import { ZoomCenterTransition } from 'vue2-transitions'
import { mapGetters } from 'vuex'
import LockScreen from './LockScreen.vue'
import ProgrammeFooter from './Footer.vue'

export default {
  components: {
    ZoomCenterTransition,
    LockScreen,
    ProgrammeFooter
  },

  data () {
    return {
      isLoading: false,
      title: '',
      sidebarBackground: 'secondary' // vue|blue|orange|green|red|primary|secondary
    }
  },
  computed: {
    ...mapGetters({
      isLoadingApi: 'isLoadingApi',
      user: 'auth/user',
      darkMode: 'settings/darkMode',
      lockScreen: 'settings/lockScreen'
    })
  },
  watch: {
    user: {
      handler (val) {
        if (!val) {
          this.$router.go()
        }
      }
    },
    darkMode: {
      handler () {
        this.setDarkMode()
      },
      immediate: true
    }
  },
  methods: {
    setDarkMode () {
      const docClasses = document.body.classList
      if (this.darkMode) {
        docClasses.remove('white-content')
      } else {
        docClasses.add('white-content')
      }
    },
    toggleSidebar () {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false)
      }
    },
    setTitle (title) {
      this.title = title
    }
  }
}
</script>
<style lang="scss" scoped>
$scaleSize: 0.95;
@keyframes zoomIn95 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  to {
    opacity: 1;
  }
}

.main-panel .zoomIn {
  animation-name: zoomIn95;
}

@keyframes zoomOut95 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}

.wrapper {
  height: 100%;
}

.main-panel .zoomOut {
  animation-name: zoomOut95;
}

.main-panel{
  border-top: none !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #FFFFFF !important;
  height: 100%;
}
.page-content{
  flex: 1;
  margin: 0 0 45px;
  height: 100%;
  overflow-y: auto;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
