import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

import auth from '@/store/modules/auth'
import dashboard from '@/store/modules/dashboard'
import states from '@/store/modules/states'
import provinces from '@/store/modules/provinces'
import settings from '@/store/modules/settings'
import sessions from '@/store/modules/sessions'
import speeches from '@/store/modules/speeches'
import speakers from '@/store/modules/speakers'
import sponsors from '@/store/modules/sponsors'
import socialEvents from '@/store/modules/social_events'
import congressInfo from '@/store/modules/congress_info'
import socialLinks from '@/store/modules/social_links'
import notes from '@/store/modules/notes'
import notifications from '@/store/modules/notifications'
import scanUsers from '@/store/modules/scan_users'
import myProgramme from '@/store/modules/my_programme'
import messages from '@/store/modules/messages'
import summaries from '@/store/modules/summaries'
import venues from '@/store/modules/venues'
import streamings from '@/store/modules/streamings'

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
Vue.use(Vuex)

// eslint-disable-next-line import/no-named-as-default-member
const store = new Vuex.Store({
  state: {
    isLoadingApi: []
  },

  getters: {
    isLoadingApi: state => state.isLoadingApi
  },

  modules: {
    auth,
    dashboard,
    states,
    provinces,
    settings,
    sessions,
    speeches,
    speakers,
    sponsors,
    socialEvents,
    congressInfo,
    socialLinks,
    notes,
    notifications,
    scanUsers,
    myProgramme,
    summaries,
    messages,
    venues,
    streamings
  },

  mutations: {
    SET_ROOT_IS_LOADING_API (state, value) {
      const index = state.isLoadingApi.indexOf(value.store)
      if (value.status && index < 0) {
        state.isLoadingApi.push(value.store)
      } else if (!value.status && index >= 0) {
        state.isLoadingApi.splice(index, 1)
      }
    }
  }
})

export default store
