<template>
  <div v-if="!isLoading" class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar, 'no-scroll': lockScreen }">
    <LockScreen v-if="lockScreen" />
    <notifications />
    <!--Share plugin (for demo purposes). You can remove it if don't plan on using it-->
    <div class="main-panel" :data="sidebarBackground">
      <div class="home-header-container">
        <div>{{ title }}</div>
      </div>
      <div class="page-content">
        <zoom-center-transition :duration="100" mode="out-in">
          <!-- your content here -->
          <div>
            <transition :duration="400" name="fade">
              <loading-panel v-show="isLoadingApi.length" stroke-color="lightblue" />
            </transition>
            <router-view @title="setTitle" />
          </div>
        </zoom-center-transition>
      </div>
      <div class="home-footer-container">
        <a v-if="homeExternalUrl !== ''" :href="homeExternalUrl">
          <img src="/img/risorse/icon-back-black.svg">
        </a>
        <router-link v-else to="/home" class="home-footer-link">
          <img src="/img/risorse/icon-back-black.svg">
          <p>MENU</p>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import { ZoomCenterTransition } from 'vue2-transitions'
import { mapGetters } from 'vuex'
import LockScreen from './LockScreen.vue'

import { LoadingPanel } from '@/theme/components'

export default {
  components: {
    ZoomCenterTransition,
    LockScreen,
    LoadingPanel
  },

  data () {
    return {
      isLoading: false,
      title: '',
      sidebarBackground: 'secondary' // vue|blue|orange|green|red|primary|secondary
    }
  },
  computed: {
    ...mapGetters({
      isLoadingApi: 'isLoadingApi',
      user: 'auth/user',
      darkMode: 'settings/darkMode',
      lockScreen: 'settings/lockScreen'
    }),
    homeExternalUrl () {
      return process.env.VUE_APP_HOME_PAGE_EXTERNAL_URL
    }
  },
  watch: {
    user: {
      handler (val) {
        if (!val) {
          this.$router.go()
        }
      }
    },
    darkMode: {
      handler () {
        this.setDarkMode()
      },
      immediate: true
    }
  },
  methods: {
    setDarkMode () {
      const docClasses = document.body.classList
      if (this.darkMode) {
        docClasses.remove('white-content')
      } else {
        docClasses.add('white-content')
      }
    },
    toggleSidebar () {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false)
      }
    },
    setTitle (title) {
      this.title = title
    }
  }
}
</script>
<style lang="scss" scoped>
$scaleSize: 0.95;
@keyframes zoomIn95 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  to {
    opacity: 1;
  }
}

.main-panel .zoomIn {
  animation-name: zoomIn95;
}

@keyframes zoomOut95 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}

.wrapper {
  height: 100%;
}

.main-panel .zoomOut {
  animation-name: zoomOut95;
}

.main-panel{
  border-top: none !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #FFFFFF !important;
  height: 100%;
}
.page-content{
  flex: 1;
  margin: 46px 0 46px;
  height: 100%;
  overflow-y: auto;
}
.home-header-container{
  background-color: #8ac653;
  color: #000000;
  padding: 12.5px 10px 12.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  top: 0px;
  left: 0px;
  font-weight: 700;
  z-index: 200;
}
.home-footer-container{
  display: flex;
  color: #000000;
  position: fixed;
  width: 100%;
  bottom: 0px;
  left: 0px;
  background-color: #8ac653;
  padding: 15px 10px 10px;
  z-index: 200;

  a{
    color: #000000;
    font-weight: 700;
    p {
      color: #000000;
      margin-left: 30px;
      margin-bottom: 0;
    }
  }
  a:hover {
    color: #000000;
  }
}
.home-footer-link{
  flex: 1;
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 0 20px;

  img{
    margin-right: 10px;
    width: 25px;
    max-height: 25px;
  }
}
</style>
