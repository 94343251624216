import DashboardNavLayout from '@/theme/layouts/DashboardNavLayout.vue'
import ProgrammeLayout from '@/theme/layouts/ProgrammeLayout.vue'
import HomeLayout from '@/theme/layouts/HomeLayout.vue'
import NextCongressLayout from '@/theme/layouts/NextCongressLayout.vue'

const Dashboard = () =>
  import(/* webpackChunkName: "dashboard" */ 'src/pages/Dashboard/Summary/Dashboard.vue')
const SponsorBlock = () =>
  import(/* webpackChunkName: "dashboard" */ 'src/pages/Dashboard/Block/SponsorBlock.vue')
const HomePage = () =>
  import(/* webpackChunkName: "dashboard" */ 'src/pages/HomePage/HomePage.vue')
const NextCongress = () =>
  import(/* webpackChunkName: "dashboard" */ 'src/pages/HomePage/NextCongress.vue')
const CongressInfoList = () =>
  import(/* webpackChunkName: "dashboard" */ 'src/pages/Dashboard/CongressInfo/List.vue')

export default [
  {
    path: '/home',
    component: HomePage
  },
  {
    path: '/home/congress-info/:key',
    component: HomeLayout,
    name: 'Home Congress Info',
    children: [
      {
        path: '/home/congress-info/:key',
        component: CongressInfoList
      }
    ]
  },
  {
    path: '/home/next-congress',
    component: NextCongressLayout,
    name: 'Next Congress',
    children: [
      {
        path: '/home/next-congress',
        component: NextCongress
      }
    ]
  },
  {
    path: '/dashboard',
    component: DashboardNavLayout,
    children: [
      {
        path: '/dashboard',
        component: Dashboard
      }
    ]
  },
  {
    path: '/sponsor-block',
    component: ProgrammeLayout,
    children: [
      {
        name: 'SponsorBlock',
        path: '/sponsor-block',
        component: SponsorBlock
      }
    ]
  }
]
