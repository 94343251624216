import ProgrammeLayout from '@/theme/layouts/ProgrammeLayout.vue'

const List = () =>
  import(/* webpackChunkName: "dashboard" */ 'src/pages/Dashboard/Notes/List.vue')

const Details = () =>
  import(/* webpackChunkName: "dashboard" */ 'src/pages/Dashboard/Notes/Details.vue')

export default [
  {
    path: '/my-notes',
    component: ProgrammeLayout,
    name: 'Notes',
    children: [
      {
        path: '/my-notes',
        component: List,
        meta: { requiresAuth: true, redirectSponsor: true }
      },
      {
        path: '/my-notes/:id',
        component: Details,
        meta: { requiresAuth: true, redirectSponsor: true }
      }
    ]
  }
]
