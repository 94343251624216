import ProgrammeLayout from '@/theme/layouts/ProgrammeLayout.vue'

const ScanQR = () =>
  import(/* webpackChunkName: "dashboard" */ 'src/pages/Dashboard/Search/ScanQR.vue')

export default [
  {
    path: '/search',
    component: ProgrammeLayout,
    name: 'Search',
    meta: { requiresAuth: true },
    children: [
      {
        path: '/search',
        component: ScanQR
      }
    ]
  }
]
