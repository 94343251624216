<template>
  <div id="loading" v-loading="true" :class="strokeClass" />
</template>
<script>
export default {
  props: {
    strokeColor: {
      type: String,
      default: null
    }
  },
  computed: {
    strokeClass () {
      if (this.strokeColor === 'red') {
        return 'red-spinner'
      } else if (this.strokeColor === 'lightblue') {
        return 'lightblue-spinner'
      }
      return null
    }
  }
}
</script>
<style lang="scss">
#loading {
  min-height: 200px;
  display: flex;
  align-items: center;
  position: absolute !important;
  width: 100%;
  z-index: 100;
  height: 100vh;
  background: #fff;
}

.red-spinner {
  .el-loading-spinner {
    top: 110px;
  }
  .el-loading-spinner .path {
    stroke: #004182 !important;
  }
}

.lightblue-spinner {
  .el-loading-spinner .path {
    stroke: rgb(61,137,204) !important;
  }
}

.el-loading-mask {
  background: transparent !important;
}
</style>
