
<template>
  <div class="header-container">
    <div>{{ title.toUpperCase() }}</div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped>
  .header-container{
    background-color: #004182;
    color: #FFFFFF;
    padding: 10px 10px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100%;
    top: 0px;
    left: 0px;
    z-index: 200;
    font-weight: 700;
  }
</style>
