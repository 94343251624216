import axios from 'axios'
import swal from 'sweetalert2'
import secureStorage from '@/utils/secureStorage'

const CordovaPlugin = {
  install (Vue, opts) {
    // Ensure that the router option is provided
    if (!opts || !opts.router) {
      return
    }

    const router = opts.router

    window.addEventListener('message', (e) => {
      if (e.data.type === 'PushNotification' &&
        !(e.data.data.additionalData.type === 'new_chat_message' &&
        router.currentRoute.name === 'chatMessageDetails' &&
        router.currentRoute.params.userId === e.data.data.additionalData.senderId)) {
        swal.fire({
          title: e.data.data.title,
          html: e.data.data.message,
          position: 'top',
          showClass: {
            popup: `
              animate__animated
              animate__fadeInDown
              animate__faster
            `
          },
          hideClass: {
            popup: `
              animate__animated
              animate__fadeOutUp
              animate__faster
            `
          },
          grow: 'row',
          showConfirmButton: true,
          showCancelButton: true,
          reverseButtons: true,
          cancelButtonText: 'Dismiss',
          confirmButtonText: 'View',
          padding: '0px',
          buttonsStyling: false,
          customClass: {
            actions: 'swal2-actions-custom',
            confirmButton: 'swal2-button-nobutton swal2-button-nobutton-right',
            cancelButton: 'swal2-button-nobutton swal2-button-nobutton-left',
            content: 'swal2-custom-container'
          }
        }).then((result) => {
          if (result.value) {
            if (e.data.data.additionalData.type === 'agenda_new_appointment') {
              router.push('/my-programme/agenda')
            } else if (e.data.data.additionalData.type === 'new_chat_message' &&
                  (router.currentRoute.name !== 'chatMessageDetails' ||
                  (router.currentRoute.name === 'chatMessageDetails' &&
                  router.currentRoute.params.userId !== e.data.data.additionalData.senderId))) {
              router.push(`/messages/${e.data.data.additionalData.senderId}`)
            } else if (e.data.data.additionalData.type === 'broadcast_message') {
              router.push(`/notifications/${e.data.data.additionalData.notificationId}`)
            } else {
              router.push('/notifications-list')
            }
          }
        })
      } else if (e.data.type === 'PushNotificationRegistration') {
        secureStorage.setItem('pushRegistrationId', e.data.data.registrationId)
        secureStorage.setItem('pushRegistrationType', e.data.data.registrationType)
        secureStorage.setItem('pushPlatform', e.data.data.platform)
        secureStorage.setItem('pushDeviceId', e.data.data.deviceId)

        window.dispatchEvent(new CustomEvent('deviceIdStored', {
          detail: { deviceId: e.data.data.deviceId }
        }))

        axios.post('/devices/store', {
          pushRegistrationId: e.data.data.registrationId,
          pushRegistrationType: e.data.data.registrationType,
          pushPlatform: e.data.data.platform,
          pushDeviceId: e.data.data.deviceId
        })
      }
    })
    Vue.prototype.thisWindow = window
    Vue.prototype.windowParent = window.parent
  }
}

export default CordovaPlugin
