<template>
  <div v-if="!isLoading" class="full-height">
    <router-view :key="$route.fullPath" />
  </div>
</template>

<script>
/* eslint-disable no-new */
import { mapGetters, mapActions } from 'vuex'

export default {
  data () {
    return {
      isLoading: true,
      sidebarBackground: 'secondary' // vue|blue|orange|green|red|primary|secondary
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      congressInfos: 'congressInfo/items'
    })
  },
  watch: {
    user: {
      handler (val) {
        if (!val) {
          this.$router.go()
        }
      }
    }
  },
  created () {
    this.reloadUser()
      .then((res) => {
        this.isLoading = false
        this.windowParent.postMessage({ type: 'getPushNotificationEnv' }, '*')
      })
      .catch(() => {
        this.$router.go()
        this.isLoading = false
      })
      // console.log('MainLayout created!')
    if (!this.congressInfos.length) {
      this.fetchCongressInfo(Object.assign({ sync: true }))
      this.fetchSpeakers(Object.assign({ sync: true }))
      this.fetchSponsors(Object.assign({ sync: true }))
      this.fetchSocialEvents(Object.assign({ sync: true }))
      this.fetchSocialLinks(Object.assign({ sync: true }))
      this.fetchSessions(Object.assign({ sync: true }))
    }
  },
  methods: {
    ...mapActions({
      reloadUser: 'auth/reload',
      fetchCongressInfo: 'congressInfo/fetch',
      fetchSpeakers: 'speakers/fetch',
      fetchSponsors: 'sponsors/fetch',
      fetchSocialEvents: 'socialEvents/fetch',
      fetchSocialLinks: 'socialLinks/fetch',
      fetchSessions: 'sessions/fetch'
    })
  }
}
</script>

<style lang="scss">
.full-height {
  height: 100%;
}
</style>
