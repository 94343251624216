import axios from 'axios'
import Crud from '@/store/utils/crud'

const crud = new Crud('/messages')

const state = () => ({
  ...crud.state,
  users: null
})
const getters = {
  ...crud.getters,
  users: state => state.users ? state.users.content.data : []
}
const actions = {
  ...crud.actions,
  async fetchUsers (context, id) {
    return await new Promise((resolve, reject) => {
      axios({
        method: 'GET',
        url: '/messages/users',
        data: null,
        headers: { 'Content-Type': 'application/json' }
      }).then((response) => {
        context.commit('SET_USERS_PAYLOAD', response)
        resolve(response)
      }, (error) => {
        reject(error)
      })
    })
  },
  resetCompanyUsers (context) {
    context.commit('CLEAR_USERS_PAYLOAD')
  }
}
const mutations = {
  ...crud.mutations,
  SET_USERS_PAYLOAD (state, response) {
    state.users = response.data
  },
  CLEAR_USERS_PAYLOAD (state) {
    state.users = null
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
