import ProgrammeLayout from '@/theme/layouts/ProgrammeLayout.vue'

const List = () =>
  import(/* webpackChunkName: "dashboard" */ 'src/pages/Dashboard/Notifications/List.vue')

const Detail = () =>
  import(/* webpackChunkName: "dashboard" */ 'src/pages/Dashboard/Notifications/Details.vue')

export default [
  {
    path: '/notifications-list',
    component: ProgrammeLayout,
    name: 'Notifications List',
    meta: { requiresAuth: true },
    children: [
      {
        path: '/notifications-list',
        component: List
      },
      {
        path: '/notifications/:id',
        component: Detail
      }
    ]
  }
]
