import ProgrammeLayout from '@/theme/layouts/ProgrammeLayout.vue'

const List = () =>
  import(/* webpackChunkName: "dashboard" */ 'src/pages/Dashboard/Streaming/List.vue')
const Details = () =>
  import(/* webpackChunkName: "dashboard" */ 'src/pages/Dashboard/Streaming/Details.vue')

export default [
  {
    path: '/streaming',
    component: ProgrammeLayout,
    name: 'Streaming',
    children: [
      {
        path: '/streaming',
        component: List,
        meta: { requiresAuth: true, redirectSponsor: true }
      },
      {
        path: '/streaming/:id',
        component: Details,
        meta: { requiresAuth: true, redirectSponsor: true }
      }
    ]
  }
]
