import ProgrammeLayout from '@/theme/layouts/ProgrammeLayout.vue'

const List = () =>
  import(/* webpackChunkName: "dashboard" */ 'src/pages/Dashboard/MyProgramme/List.vue')

export default [
  {
    path: '/my-programme',
    component: ProgrammeLayout,
    name: 'My Programme',
    meta: { requiresAuth: true },
    children: [
      {
        path: '/my-programme',
        component: List
      }
    ]
  }
]
