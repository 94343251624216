<template>
  <div class="footer-container">
    <a v-if="homeExternalUrl !== ''" :href="homeExternalUrl">
      <img src="/img/risorse/pagebar-hamburger-menu.svg">
    </a>
    <router-link v-else to="/home" class="footer-link">
      <img src="/img/risorse/pagebar-hamburger-menu.svg">
    </router-link>
    <a to="#" class="footer-link" @click="$router.back()">
      <img src="/img/risorse/icon-back.svg">
    </a>
    <router-link to="/dashboard" class="footer-link">
      <img src="/img/risorse/icon-home.svg">
    </router-link>
    <router-link to="/my-programme" class="footer-link">
      <img src="/img/risorse/icon-my-agenda.svg">
    </router-link>
    <div v-if="isAuthenticated" class="footer-link">
      <img class="logout-icon" src="/img/risorse/icon-logout.svg" @click="doLogout">
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated'
    }),
    homeExternalUrl () {
      return process.env.VUE_APP_HOME_PAGE_EXTERNAL_URL
    }
  },
  methods: {
    ...mapActions({
      logout: 'auth/logout'
    }),
    doLogout () {
      this.logout().then((res) => {
        // this.$router.push('/login')
      })
    }
  }
}
</script>

<style lang="scss">
  .footer-container{
    display: flex;
    color: #FFFFFF;
    position: fixed;
    width: 100%;
    bottom: 0px;
    left: 0px;
    background-color: #004182;
    padding: 12.5px 10px 12.5px;
    z-index: 200;

    a{
      color: #FFFFFF;
    }
  }
  .footer-link{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    img{
      margin-right: 10px;
      width: 25px;
      max-height: 25px;
    }
  }
</style>
