import ProgrammeLayout from '@/theme/layouts/ProgrammeLayout.vue'

const List = () =>
  import(/* webpackChunkName: "dashboard" */ 'src/pages/Dashboard/CongressInfo/List.vue')

export default [
  {
    path: '/congress-info/:key',
    component: ProgrammeLayout,
    name: 'Congress Info',
    children: [
      {
        path: '/congress-info/:key',
        component: List
      }
    ]
  }
]
