import ProgrammeLayout from '@/theme/layouts/ProgrammeLayout.vue'

const Map = () =>
  import(/* webpackChunkName: "dashboard" */ 'src/pages/Dashboard/Map/Map.vue')

export default [
  {
    path: '/map',
    component: ProgrammeLayout,
    name: 'Map',
    children: [
      {
        path: '/map',
        component: Map
      }
    ]
  }
]
