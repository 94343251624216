import ProgrammeLayout from '@/theme/layouts/ProgrammeLayout.vue'

const List = () =>
  import(/* webpackChunkName: "dashboard" */ 'src/pages/Dashboard/Sessions/List.vue')

const Details = () =>
  import(/* webpackChunkName: "dashboard" */ 'src/pages/Dashboard/Sessions/Details.vue')

export default [
  {
    path: '/programme-list',
    component: ProgrammeLayout,
    name: 'Programme List',
    children: [
      {
        path: '/sessions-list',
        component: List
      },
      {
        path: '/sessions/:id',
        component: Details
      }
    ]
  }
]
