import MainLayout from 'src/theme/layouts/MainLayout.vue'

import baseRoutes from '@/routes/dashboard/base.routes.js'
import sponsorsRoutes from '@/routes/dashboard/sponsors.routes'
import speakersRoutes from '@/routes/dashboard/speakers.routes'
import sessionsRoutes from '@/routes/dashboard/sessions.routes.js'
import searchRoutes from '@/routes/dashboard/search.routes.js'
import socialEventsRoutes from '@/routes/dashboard/social_events.routes.js'
import congressInfoRoutes from '@/routes/dashboard/congress_info.routes.js'
import socialLinksRoutes from '@/routes/dashboard/social_links.routes.js'
import notesRoutes from '@/routes/dashboard/notes.routes.js'
import mapRoutes from '@/routes/dashboard/map.routes.js'
import notificationsRoutes from '@/routes/dashboard/notifications.routes.js'
import myProgramme from '@/routes/dashboard/my_programme.routes.js'
import summariesRoutes from '@/routes/dashboard/summaries.routes.js'
import messagesRoutes from '@/routes/dashboard/messages.routes'
import streamingRoutes from '@/routes/dashboard/streaming.routes'

const dashboardPages = {
  path: '/',
  component: MainLayout,
  redirect: process.env.VUE_APP_SHOW_HOME_PAGE === 'true' ? '/home' : '/dashboard',
  name: 'Dashboard',
  meta: {
    auth: true
  },
  children: [
    ...baseRoutes,
    ...sessionsRoutes,
    ...searchRoutes,
    ...speakersRoutes,
    ...sponsorsRoutes,
    ...socialEventsRoutes,
    ...congressInfoRoutes,
    ...socialLinksRoutes,
    ...notesRoutes,
    ...mapRoutes,
    ...notificationsRoutes,
    ...myProgramme,
    ...summariesRoutes,
    ...messagesRoutes,
    ...streamingRoutes
  ]
}

export default dashboardPages
