import ProgrammeLayout from '@/theme/layouts/ProgrammeLayout.vue'

const List = () =>
  import(/* webpackChunkName: "dashboard" */ 'src/pages/Dashboard/SocialLinks/List.vue')

const Details = () =>
  import(/* webpackChunkName: "dashboard" */ 'src/pages/Dashboard/SocialLinks/Details.vue')

export default [
  {
    path: '/social-links',
    component: ProgrammeLayout,
    name: 'Social Links',
    children: [
      {
        path: '/social-links-list',
        component: List
      },
      {
        path: '/social-links/:id',
        component: Details
      }
    ]
  }
]
